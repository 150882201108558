<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('pica-syndrom')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p v-for="paragraph in $t('disorders-pica-syndrom')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "pica-syndrom",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "chewing-and-spitting",
      url: "/disorders/chewing-and-spitting"
    },
    previous: {
      slug: "night-eating-syndrom",
      url: "/disorders/night-eating-syndrom"
    },
    sources: [
      "[1] GmbH DMS. Pica-Syndrom. DocCheck Flexikon. Accessed August 4, 2021. https://flexikon.doccheck.com/de/Pica-Syndrom",
      "[2] Pica - Psychische Gesundheitsstörungen. MSD Manual Ausgabe für Patienten. Accessed August 2, 2021. https://www.msdmanuals.com/de-de/heim/psychische-gesundheitsst%C3%B6rungen/essst%C3%B6rungen/pica"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("pica-syndrom"),
          disabled: true
        }
      ];
    }
  }
};
</script>
